import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter, Route, Routes} from "react-router-dom";

import store from './redux/store'
import {Provider} from 'react-redux'

import {LiffProvider} from 'react-liff';
import App from './App';

import { Result } from 'antd';


const liffId = process.env.REACT_APP_LINE_LIFF_ID;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <LiffProvider liffId={liffId}>
            <BrowserRouter>
                <Routes>
                    <Route path="liff" element={<App/>}/>
                    <Route
                        path="*"
                        element={
                            <Result
                                status="404"
                                title="404"
                                subTitle="Sorry, the page you visited does not exist."
                            />
                    }
                    />
                </Routes>
            </BrowserRouter>
        </LiffProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
