import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        customerList: [],
        selectCustomer: null,

        itemObj: {}, // name: count
        selectItems: {},
        itemsWarning: {},

        currentStep: 0,
        isTagClose: false,

        selectDate: null,
        typeRemark: null,
        typeUser: null,

        userID: null,
        userPic: null,
        displayName: null,
    },
    reducers: {
        setUserLine: (state, action) => {
            state.userID = action.payload.userID
            state.userPic = action.payload.pictureUrl
            state.displayName = action.payload.displayName

            const aname = action.payload.typeUser
            if (aname.trim().length !== 0){
                state.typeUser = action.payload.typeUser
            } else {
                state.typeUser = null
            }
        },

        setSelectCustomer: (state, action) => {
            state.selectCustomer = action.payload
        },

        setSelectDate: (state, action) => {
            const aname = action.payload
            if (aname.trim().length !== 0){
                state.selectDate = action.payload
            } else {
                state.selectDate = null
            }
        },

        // setDefaultSelectDate: (state, action) => {
        //     state.selectDate = null
        // },

        setTypeUser: (state, action) => {
            const aname = action.payload
            if (aname.trim().length !== 0){
                state.typeUser = action.payload
            } else {
                state.typeUser = null
            }
        },

        setTypeRemark: (state, action) => {
            state.typeRemark = action.payload
        },

        setCustomerList: (state, action) => {
            state.customerList = action.payload
        },

        setItemObj: (state, action) => {
            state.itemObj = action.payload
        },

        setItemWarning: (state, action) => {
            state.itemsWarning = action.payload
        },

        updateItemObj: (state, action) => {
            const akey = action.payload.akey
            const value = action.payload.value
            state.itemObj = {
                ...state.itemObj,
                [akey]: state.itemObj[akey] + value > 0 && state.itemObj[akey] + value <= 5 ? state.itemObj[akey] + value : state.itemObj[akey] + value <= 0 ? 0 : state.itemObj[akey]
            }

            if (state.itemObj[akey] === 0) {
                delete state.selectItems[akey]
            } else {
                state.selectItems = {...state.selectItems, [akey]: state.itemObj[akey]}
            }
        },

        tagClose: (state, action) => {
            // eslint-disable-next-line array-callback-return
            const akey = action.payload
            state.itemObj = {...state.itemObj, [akey]: 0}
            delete state.selectItems[akey]
        },

        setCurrentStep: (state, action) => {
            const value = action.payload
            if (value === 0) {
                // eslint-disable-next-line array-callback-return
                Object.keys(state.itemObj).map((key) => {
                    state.itemObj[key] = 0
                })

                state.selectCustomer = null
                state.selectItems = {}
                state.selectDate = null
                state.typeRemark = null
                // state.typeUser = null

                state.currentStep = value
            } else if (state.selectCustomer && value === 1) {
                state.isTagClose = true

                // state.selectDate = null
                // state.typeRemark = null
                // state.typeUser = null

                state.currentStep = value
            } else if (state.selectCustomer && Object.keys(state.selectItems).length > 0 && value === 2) {
                state.isTagClose = false
                state.currentStep = value
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    setSelectDate,
    setTypeUser,
    setTypeRemark,
    setCustomerList,
    setItemObj,
    setSelectCustomer,
    updateItemObj,
    tagClose,
    setCurrentStep,
    setUserLine,
    setItemWarning,
} = userSlice.actions

export default userSlice.reducer